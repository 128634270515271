export default [
  {
    title: 'Dashboards',
    icon: 'HomeIcon',
    route: 'dashboard-analytics',
    permissions: ['dashboard', 'faq', 'page'],
  },
  {
    title: 'FAQs',
    icon: 'HelpCircleIcon',
    route: 'faqs',
    permissions: ['faq'],
  },
  {
    header: 'Ads Management',
    permissions: ['advertisement', 'advertisementcategory'],
  },
  {
    title: 'Advirtisements',
    icon: 'Volume2Icon',
    route: 'advertisements',
    permissions: ['advertisement'],
  },
  {
    title: 'Ads Categories',
    icon: 'Volume1Icon',
    route: 'advertisements/categories',
    permissions: ['advertisementcategory'],
  },
  {
    header: 'Roles & Users',
    permissions: ['users', 'roles'],
  },
  {
    title: 'Users',
    icon: 'UserIcon',
    route: 'users',
    permissions: ['users'],
  },
  {
    title: 'Roles & Permissions',
    icon: 'UsersIcon',
    route: 'roles',
    permissions: ['roles'],
  },
]
